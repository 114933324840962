import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { Container, Col } from 'react-bootstrap';
import Sidebar from '../../../components/writing-franklin/v1/Sidebar';
import PlayerVideo from '../../../../assets/img/svg/animate/PlayerVideo';
import FullModal from '../../../components/partials/modals/FullModal';
import PosterEN from '../../../../assets/img/png/illustrations/posters/poster-writing-franklin-fr.png';
import PosterFR from '../../../../assets/img/png/illustrations/posters/poster-writing-franklin-en.png';
import VideoOnboardingEN from '../../../../assets/video/onboarding-writing-franklin-en.mov';
import VideoOnboardingFR from '../../../../assets/video/onboarding-writing-franklin-fr.mov';
import Wysiwyg from '../../../components/writing-franklin/v1/Wysiwyg';
import Onboarding from '../../../components/writing-franklin/v1/Onboarding';
import Suggests from '../../../components/writing-franklin/v1/Suggests';

class WritingFranklin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            currentArticleId: null,
            suggests: [],
            sections: '',
            steps: [
                {
                  target: '.tour-create-article',
                  content: this.props.t('tour.createArticle'),
                }
              ]
        }
        this.handleArticleChange = this.handleArticleChange.bind(this);
        this.updateWordsCount = this.updateWordsCount.bind(this);
        this.updateLastSaved = this.updateLastSaved.bind(this);
        this.handleCreateNewSubject = this.handleCreateNewSubject.bind(this);
        this.handleCreateDemoSubject = this.handleCreateDemoSubject.bind(this);
        this.updateSuggests = this.updateSuggests.bind(this);
        this.updateSections = this.updateSections.bind(this);     
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if(!localStorage.getItem('af_token')) {
            this.setState({
                redirect: true
            });
        }
    }
   
    customHeadElement() {
        return (
            <Helmet>
                <title>{this.props.t('title.writingAF')}</title>
                <meta name="description" content={this.props.t('description.writingAF')}/>
                <meta name="robots" content="noindex, follow"/>
            </Helmet>
        );
    }

    handleCreateNewSubject() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/create', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.handleArticleChange(res.message);
        })
    }

    handleCreateDemoSubject() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/create-demo', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ 
                title: '10 ways to improve content marketing in a businesss', 
                content: '1. Determine Objectives for Content Marketing \n \n Why are you doing this at all? What is content going to do for your organization? Create awareness? Generate leads? Improve loyalty and retention? \n Remember: The goal isn’t to be good at content marketing. The goal is to be good at business because of content marketing.'
            })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.handleArticleChange(res.message);
        })
    }

    handleArticleChange(articleId) {
        this.setState({
            currentArticleId: articleId
        });
    }

    updateWordsCount(value) {
        this.setState({
            wordsCount: value
        });
    }

    updateLastSaved(value) {
        this.setState({
            lastSaved: value
        });
    }

    updateSuggests(suggests) {
        var arr = suggests.data.map((sugg) => sugg.data.map((sugg2) => sugg2.suggestions).flat(1)).flat(1);
        var arrState = [...new Set(arr)];
        this.setState({
            suggests: arrState
        });
    }

    updateSections(sections) {
        this.setState({
            sections: sections
        });
    }

    render() {

        const { t } = this.props;
        const { steps } = this.state;

        if(this.state.redirect) {
            return <Navigate to={t('url.signIn')}/>
        } 

        const video = 
            <Col lg="12" xl="6" className="mx-auto mb-5" style={{ marginTop: '-3rem' }}>
                <div class="video-onboarding-tool d-flex align-items-center justify-content-center position-relative w-100 cursor-pointer" style={ i18n.language !== 'fr' ? { backgroundImage: 'url(' + PosterFR + ')' } : { backgroundImage: 'url(' + PosterEN + ')' } }>
                    <div class="play-btn position-relative"><PlayerVideo/></div>
                    <span class="video-tooltip position-absolute color-light rounded py-1 px-3">{t('writingFranklin.onboarding.tooltipVideo')}</span>
                </div>
            </Col>;

        return (
            <div class={this.props.bannerIsActive ? 'layout-style-banner' : 'layout-style'}>  
                {this.customHeadElement()}
                <Container id="writingFranklin" className="px-0 mt-6">

                    {this.state.currentArticleId && !isNaN(this.state.currentArticleId) ?
                        <FullModal modalShowed={true} trigger={video}>
                            {/* <Loader loaderDisplayed content={t('actions.loading')} className="position-absolute h-100 w-100"/> */}
                            <video width="100%" height="100%" poster={i18n.language !== 'fr' ? PosterFR : PosterEN} autoplay controls class="video-onboarding rounded" style={{ zIndex: 1 }}>
                                <source src={i18n.language !== 'fr' ? VideoOnboardingEN : VideoOnboardingFR} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </FullModal> : ""
                    }

                    <p class="mb-3 fz-14">{t('writingFranklin.languageSupported')}</p>

                    <div class="w-100 d-flex flex-column flex-xl-row">
                        <Sidebar 
                            handleCreateNewSubject={this.props.handleCreateNewSubject} 
                            handleArticleChange={this.handleArticleChange} 
                            isConnected={this.state.isConnected} 
                            currentLocation={this.props.location} 
                            currentArticle={this.state.currentArticleId}
                        />
                        <Col className="block-style overflow-visible mx-auto p-3 w-100" style={{ zIndex: 1 }}>
                            {this.state.currentArticleId ?
                                <Wysiwyg 
                                    currentArticle={this.state.currentArticleId} 
                                    tokens={this.state.currentTokens} 
                                    creativity={this.state.currentCreativity} 
                                    updateWordsCount={this.updateWordsCount} 
                                    updateLastSaved={this.updateLastSaved}
                                    updateSettings={this.updateSettings}
                                    isPro={this.props.isPro}
                                    updateSections={this.updateSections}
                                /> 
                            : 
                                <Onboarding handleCreateNewSubject={this.handleCreateNewSubject} handleCreateDemoSubject={this.handleCreateDemoSubject}/>
                            }
                        </Col>
                        {this.state.currentArticleId && !isNaN(this.state.currentArticleId) ?
                            <Suggests 
                                currentLocation={this.props.location} 
                                suggests={this.state.suggests} 
                                articleId={this.state.currentArticleId} 
                                handleSuggests={this.updateSuggests} 
                                sections={this.state.sections}
                            /> : ""
                        }
                    </div>
                </Container>
            </div>
        )
    }
}

export default withTranslation()(WritingFranklin);