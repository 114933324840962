import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Container, Col } from 'react-bootstrap';
import { Navigate} from 'react-router-dom';
import H1 from '../../components/elements/title/H1';
import H2 from '../../components/elements/title/H2';
import Tick from '../../../assets/img/svg/Tick';
import PmyBtn from '../../components/button/PmyBtn';
import Logo1 from '../../../assets/img/png/partners/logo-argos-veterinaire.png';
import Logo2 from '../../../assets/img/png/partners/logo-sud-ouest.png';
import Logo3 from '../../../assets/img/png/partners/logo-3Dnatives.png';
import Logo4 from '../../../assets/img/png/partners/logo-timetonic.png';
import Logo5 from '../../../assets/img/png/partners/logo-urgo.png';
import Logo6 from '../../../assets/img/png/partners/logo-open-ai.png';
import CardTestimonial from '../../components/elements/cards/CardTestimonial';
import HeleneHemon from '../../../assets/img/png/testimonials/helene-hemon.png';
import RomainCernik from '../../../assets/img/png/testimonials/romain-cernik.jpg';
import MelanieRenard from '../../../assets/img/png/testimonials/melanie-renard.png';
import CardBlog from '../../components/elements/cards/CardBlog';
import Blob from '../../../assets/img/svg/decorating/Blob';
import Dots from '../../../assets/img/svg/decorating/Dots';
import Input from '../../components/form/Input';
class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            keywordSearch: '',
            languageSearchCode: i18n.t('form.filters.languages.selectedCode'),
            countrySearchCode: i18n.t('form.filters.countries.selectedCode'),
            redirect: false,
            selectedCategoryIndex: 0,
            email: "",
            firstName: "",
            emailStatus: null
        }
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.requestFanklin = this.requestFanklin.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleEmailSend = this.handleEmailSend.bind(this);
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    customHeadElement() {
        return (
            <Helmet>
                <title>{this.props.t('title.homepage')}</title>
                <meta name="description" content={this.props.t('description.homepage')}/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
        );
    }
    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    handleFirstNameChange(e) {
        this.setState({
            firstName: e.target.value
        });
    }

    async handleEmailSend() {
        if (!this.state.email) {
            this.setState({ emailStatus: 'error' });
            return;
        }

        const apiKey = process.env.REACT_APP_BREVO_API_KEY;
        if (!apiKey) {
            console.error('Brevo API key not found in environment variables');
            this.setState({ emailStatus: 'error' });
            return;
        }

        try {
            const response = await fetch('https://api.brevo.com/v3/contacts', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'api-key': apiKey
                },
                body: JSON.stringify({
                    email: this.state.email,
                    listIds: [31],
                    attributes: {
                        PRENOM: this.state.firstName,
                        SOURCE: 'Website Newsletter'
                    },
                    updateEnabled: true
                })
            });

            // Handle 204 No Content response
            if (response.status === 204) {
                this.setState({ 
                    emailStatus: 'success',
                    email: '', // Clear the input
                    firstName: '' // Clear the first name input
                });
                return;
            }

            const data = await response.json();

            if (!response.ok) {
                console.error('Brevo API Error:', data);
                if (data.code === 'duplicate_parameter') {
                    this.setState({ emailStatus: 'duplicate' });
                    return;
                }
                throw new Error(data.message || 'Network response was not ok');
            }

            this.setState({ 
                emailStatus: 'success',
                email: '', // Clear the input
                firstName: '' // Clear the first name input
            });
        } catch (error) {
            console.error('Error adding contact:', error);
            this.setState({ emailStatus: 'error' });
        }
    }

    handleKeywordChange(e) {
        this.setState({
            keywordSearch: e.target.value
        });
    }

    handleCountryChange(value) {
        this.setState({
            countrySearchCode: value
        });
    }
    
    handleLanguageChange(value) {
        this.setState({
            languageSearchCode: value
        });
    }

    requestFanklin = () => {
        this.setState({
            redirect: true
        });
    }

    render() {

        const { t } = this.props;
        if(this.state.redirect) {
            return <Navigate to={ t('url.resultAF') + this.state.keywordSearch.replace(/ /g, '-') + '?lang=' + this.state.languageSearchCode + '&country=' + this.state.countrySearchCode}/>
        }

        return (
            <div id="home">
                {this.customHeadElement()}
                <div id="top"></div>

                {/* Hero */}
                <section class={this.props.bannerIsActive ? 'page-section-1 pt-hero-banner position-relative' : 'page-section-1 pt-hero position-relative'}>
                    <Container className="d-flex flex-column flex-xl-row position-relative">
                        <Col xs="12" md="10" lg="9" xl="9" className="px-0 mx-auto">
                            <H1 
                                title={t('homepage.hero.h1a')} 
                                className="mb-4 lh-1"
                            />
                            <p className="subtitle mb-4 lh-1">{t('homepage.hero.subtitle')}</p>
                            <p className="catchphrase mb-4 lh-1">{t('homepage.hero.paragraph.1')} <span className="beta2">{t('homepage.hero.paragraph.1.2')}</span></p>
                            <p className="catchphrase mb-4 lh-1">{t('homepage.hero.paragraph.2')} <span className="beta2">{t('homepage.hero.paragraph.2.2')}</span></p>
                            <ul class="catch-features">
                                <li class="d-flex flex-row align-items-baseline mb-3 fz-20">
                                    <Tick width="16"/><span class="ml-2 pl-1">{t('homepage.hero.dots1')}</span>
                                </li>
                                <li class="d-flex flex-row align-items-baseline mb-3 fz-20">
                                    <Tick width="16"/><span class="ml-2 pl-1">{t('homepage.hero.dots2')}</span>
                                </li>
                                <li class="d-flex flex-row align-items-baseline fz-20">
                                    <Tick width="16"/><span class="ml-2 pl-1">{t('homepage.hero.dots3')}</span>
                                </li>
                            </ul>
                            
                            <p className="catchphrase mt-5 lh-1">{t('homepage.hero.beta')} <span className="beta2">{t('homepage.hero.beta2')}</span></p>
                            
                        <div id="whitelist-form" className="d-flex flex-column flex-lg-row gap-3">
                            <Input
                                type="text"
                                label={t('homepage.form.email.label')}
                                for="contactEmail"
                                onChange={this.handleEmailChange}
                                value={this.state.email}
                                containerStyle="mt-3 flex-grow-1"
                                required={true}
                                className={this.state.emailStatus === 'error' ? 'is-invalid' : ''}
                            />
                            <Input
                                type="text"
                                label={t('homepage.form.firstName.label')}
                                for="contactFirstName"
                                onChange={this.handleFirstNameChange}
                                value={this.state.firstName}
                                containerStyle="mt-3 flex-grow-1"
                                required={true}
                            />
                        </div>
                        {this.state.emailStatus === 'success' && (
                            <div className="text-success mt-2">{t('homepage.form.success')}</div>
                        )}
                        {this.state.emailStatus === 'error' && (
                            <div className="text-danger mt-2">{t('homepage.form.error')}</div>
                        )}
                        {this.state.emailStatus === 'duplicate' && (
                            <div className="text-warning mt-2">{t('homepage.form.duplicate')}</div>
                        )}
                        <PmyBtn onClick={this.handleEmailSend} textBtn={t('homepage.form.submit')} btnIsMediumPmyOutlineFull containerStyle='btn-profile mt-1'/>
                        </Col>

                        <Dots className="dots-1"/>
                    </Container>
                </section>

                {/* Partners */}
                <section class="page-section-2">
                    <Container>
                        <H2 className="mb-5 pb-5" title={t('homepage.h2.partners')}/>
                        <div class="grid-container">
                            <img src={Logo1} loading="lazy" alt="Logo Argos Vétérinaire" class="img-fluid partners partners-md"/>
                            <img src={Logo2} loading="lazy" alt="Logo Sud Ouest" class="img-fluid partners partners-lg"/>
                            <img src={Logo3} loading="lazy" alt="Logo La French Tech" class="img-fluid partners partners-md"/>
                            <img src={Logo4} loading="lazy" alt="Logo Sortvoices" class="img-fluid partners partners-lg"/>
                            <img src={Logo5} loading="lazy" alt="Logo Urgo" class="img-fluid partners partners-sm"/>
                            <img src={Logo6} loading="lazy" alt="Logo OpenAI" class="img-fluid partners partners-sm"/>
                        </div>
                    </Container>
                </section>

                {/* Testimonials */}
                <section class="page-section-3 position-relative px-0 px-lg-4 px-xl-5 my-5 py-5">
                    <Container className="slider-container position-relative d-flex flex-row justify-content-xl-around pb-3 pb-lg-0 px-0 px-xl-0">
                        <Col md="12" lg="4" xl="3" className="slider-item px-0">
                            <CardTestimonial
                                img={HeleneHemon}
                                testimonial={t('homepage.testimonials.1.text')}
                                fullname={t('homepage.testimonials.1.author')}
                                jobAndCompany={t('homepage.testimonials.1.about')}
                            />
                        </Col>
                        <Col md="12" lg="4" xl="3" className="slider-item px-0">
                            <CardTestimonial   
                                img={RomainCernik}
                                testimonial={t('homepage.testimonials.2.text')}
                                fullname="Romain Cernik"
                                jobAndCompany="Growth and digital hacker"
                            />
                        </Col>
                        <Col md="12" lg="4" xl="3" className="slider-item px-0">
                            <CardTestimonial 
                                img={MelanieRenard}
                                testimonial={t('homepage.testimonials.3.text')}
                                fullname={t('homepage.testimonials.3.author')}
                                jobAndCompany={t('homepage.testimonials.3.about')}
                            />  
                        </Col>
                        <Blob blob="3" purple className="blob-4"/>
                    </Container>
                </section>

                {/* Usecases */}
                <section class="page-section-5 mt-6 position-relative">
                    <Blob blob="3" purple className="blob-8"/>
                    <Container className="px-0">
                        <H2 className="mb-5" title={[ t('homepage.h2.usecase1'), <br/>, t('homepage.h2.usecase2') ]}/>
                        <div className="d-flex flex-column flex-md-row justify-content-start gap-4">
                            {i18n.language === 'fr' ?
                                <>
                                    <Col xs="12" md="6" lg="4" xl="3" className="px-0">
                                        <CardBlog
                                            redirectTo="https://blog.askingfranklin.com/comment-optimiser-votre-redaction-pour-un-contenu-seo-friendly/"
                                            img="https://blog.askingfranklin.com/wp-content/uploads/2021/05/Comment-optimiser-votre-rédaction-pour-un-contenu-SEO-friendly.png"
                                            title="Comment optimiser votre rédaction pour un contenu SEO friendly ?"
                                            readMore="Voir le cas d'usage"
                                        />
                                    </Col>
                                    <Col xs="12" md="6" lg="4" xl="3" className="px-0">
                                        <CardBlog
                                            redirectTo="https://blog.askingfranklin.com/comment-rediger-pour-etre-lu-et-augmenter-votre-engagement/"
                                            img="https://blog.askingfranklin.com/wp-content/uploads/2021/05/écrire-du-contenu-qui-engage-son-audience.jpg"
                                            title="Comment rédiger pour être lu et augmenter votre engagement ?"
                                            readMore="Voir le cas d'usage"
                                        />
                                        <Dots className="dots-3"/>
                                    </Col>
                                </>
                            :
                                <Col xs="12" md="6" lg="4" xl="3" className="px-0">
                                    <CardBlog
                                        redirectTo="https://blog.askingfranklin.com/auto-write-a-super-engaging-blog-pos/"
                                        img="https://blog.askingfranklin.com/wp-content/uploads/2021/06/Auto-write-a-super-engaging-blog-post-in-lightning-speed.jpg"
                                        title="⚡️ Auto-write a super engaging blog post in lightning speed"
                                        readMore="See the use case"
                                    />
                                    <Dots className="dots-3"/>
                                </Col>
                            }
                        </div>
                    </Container>
                </section>
                
                {/* CTA */}
                <section class="page-section-7 position-relative d-flex flex-column align-items-center">
                    <H2 title={t('homepage.h2.cta')} className="my-5 pb-5"/>
                    <PmyBtn redirectTo="#top" linkIsLargePmyFull textLink={!this.props.isConnected ? t('homepage.cta.1') : t('homepage.cta.2')} customBtnClass="w-sm-100"/>
                </section>

            </div>
        )
    }    
}

export default withTranslation()(Home);