import React from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import Topbar from '../../../components/writing-franklin/v2/Topbar';
import Heading from '../../../components/writing-franklin/v2/Heading';
import Wysiwyg from '../../../components/writing-franklin/v2/Wysiwyg';
import Suggests from '../../../components/writing-franklin/v2/Suggests';
import PmyBtn from '../../../components/button/PmyBtn';

class WritingMode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            currentArticleId: null,
            suggests: [],
            sections: ''
        }
        this.handleArticleChange = this.handleArticleChange.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        this.updateWordsCount = this.updateWordsCount.bind(this);
        this.updateLastSaved = this.updateLastSaved.bind(this);
        this.updateSections = this.updateSections.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if(!localStorage.getItem('af_token')) {
            this.setState({
                redirect: true
            });
        }
        else {
            this.props.handleFuncNavbar();
            this.props.handleFuncFooter();
        }
    }
   
    customHeadElement() {
        return (
            <Helmet>
                <title>{this.props.t('title.writingAF')}</title>
                <meta name="description" content={this.props.t('description.writingAF')}/>
                <meta name="robots" content="noindex, follow"/>
            </Helmet>
        );
    }

    handleArticleChange(articleId) {
        this.setState({
            currentArticleId: articleId
        });
    }

    updateSettings(settings) {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/' + this.state.currentArticle + '/settings', {
            headers: {
                'Authorization': token
            },
            method: 'GET'
        })
        .then((res2) => {
            return res2.json();
        })
        .then((res2) => {
            this.setState({
                creativityStr:(res2.data.creativity && res2.data.creativity.length > 0) ? res2.data.creativity : 'middle',
                textLengthStr:(res2.data.textLength && res2.data.textLength.length > 0) ? res2.data.textLength : 'short',
                currentCreativity: settings.creativity,
                currentTokens: settings.tokens,
            });
        })  
    }

    updateWordsCount(value) {
        this.setState({
            wordsCount: value
        });
    }

    updateLastSaved(value) {
        this.setState({
            lastSaved: value
        });
    }

    updateSections(sections) {
        this.setState({
            sections: sections
        });
    }

    render() {

        const { t } = this.props;

        if(this.state.redirect) {
            return <Navigate to={t('url.signIn')}/>
        }

        return (
            <div class={this.props.bannerIsActive ? 'layout-style-banner pb-0 overflow-hidden' : 'layout-style pb-0 overflow-hidden'}>

                {this.customHeadElement()}

                <Topbar 
                    bannerIsActive={this.props.bannerIsActive} 
                    currentCreativity={this.props.currentCreativity}
                    updateSettings={this.updateSettings} 
                    currentTokens={this.props.currentTokens}
                />

                <Container id="writingFranklin" className="writing-mode bgc-light border-right border-left w-100 mb-5 pt-4 pb-5 px-3 px-lg-4">
                    <Heading
                        creativity={this.state.currentCreativity} 
                        tokens={this.state.currentTokens} 
                        updateSettings={this.props.updateSettings} 
                        wordsCount={this.state.wordsCount} 
                        updateWordsCount={this.updateWordsCount} 
                        lastSaved={this.state.lastSaved} 
                        updateLastSaved={this.updateLastSaved}
                        credits={this.state.credits}
                        isPro={this.props.isPro} 
                        updateSettings={this.updateSettings}
                    />
                    <Row className="d-flex flex-column flex-lg-row pt-5 px-0" style={{ minHeight: 'inherit' }}>
                        <Wysiwyg 
                            currentArticle={this.state.currentArticleId} 
                            credits={this.state.credits}
                            isPro={this.props.isPro}
                            updateSections={this.updateSections}
                            minLength={this.props.minLength}
                            prevLength={this.props.prevLength}
                            content={this.props.content}
                            contentLength={this.props.contentLength}
                            countdown={this.props.countdown}
                            isLoading={this.props.isLoading}
                            noCredits={this.props.noCredits}
                        /> 
                        <Suggests 
                            articleId={this.state.currentArticleId} 
                            suggests={this.state.suggests}
                            sections={this.state.sections}
                            handleSuggests={this.updateSuggests} 
                        />
                    </Row>
                </Container>

                <footer class="wf-footer position-fixed border-top bgc-light w-100 px-4 px-xl-5 py-2">
                    <Container className="px-0 d-flex flex-lg-row align-items-center h-100 min-h-inherit">
                        {this.props.noCredits ?
                            <>
                                <PmyBtn onMouseDown={this.handleOpenReloadCredits} type="button" btnIsMediumPmyFull textBtn={t('writingFranklin.heading.mainCta')}/>
                                <p class="ml-lg-3 mt-2 mt-lg-0 fz-14 color-danger">{t('writingFranklin.writingMode.noCredits')}</p>
                            </>
                        :
                            <>
                                <PmyBtn onMouseDown={this.generateContent} type="button" isDisabled={(this.props.contentLength < this.props.minLength) || (this.props.countdown > 1) || (this.props.contentLength < this.props.prevLength + this.props.minLength)} btnIsMediumPmyFull textBtn={this.props.isLoading ? <div class="spinner spinner-light"></div> : t('writingFranklin.writingMode.ctaGenerate')} title={this.props.isLoading ? t('actions.loading') : t('writingFranklin.writingMode.ctaGenerate')} className="generate-content"/>
                                {this.props.countdown < 1 && this.props.contentLength < this.props.minLength && 
                                    <p class="ml-lg-3 mt-2 mt-lg-0 fz-14">{t('writingFranklin.writingMode.countChar1') + (this.props.minLength - this.props.contentLength) + t('writingFranklin.writingMode.countChar2')}</p>
                                }
                                {this.props.countdown > 1 && 
                                    <p class="ml-lg-3 mt-2 mt-lg-0 fz-14">{t('writingFranklin.writingMode.countdown1') + this.props.countdown + t('writingFranklin.writingMode.countdown2')}</p>
                                }
                                {this.props.contentLength > this.props.minLength && this.props.countdown < 1 && this.props.contentLength < this.props.prevLength + this.props.minLength && 
                                    <p class="ml-lg-3 mt-2 mt-lg-0 fz-14">{t('writingFranklin.writingMode.rewriteChar', (this.props.prevLength + this.props.minLength) - this.props.contentLength )}</p>
                                }
                            </>
                        }
                    </Container>
                </footer>

            </div>
        )
    }
}

export default withTranslation()(WritingMode);