import React from 'react';
import i18n from '../../../i18n';
import { withTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/img/svg/switch/Logo';
import MenuLink from '../../components/elements/link/MenuLink';
import Rocket from '../../../assets/img/svg/navigation/Rocket';
import PmyBtn from '../button/PmyBtn';
import ProfileAccess from '../elements/ProfileAccess';
import MobileMenu from './MobileMenu';

class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.handleLogOut = this.handleLogOut.bind(this);
    }

    handleLogOut(event) {
        event.preventDefault();
        localStorage.removeItem('af_token');
        localStorage.removeItem('af_refresh_token');
        localStorage.removeItem('af_username');
        localStorage.removeItem('af_is_sub');
        localStorage.removeItem('product');
        window.location.replace(this.props.t('url.home'));
    }

    render() {
        const { t } = this.props;
        const isConnected = this.props.isConnected;
        const isPro = this.props.isPro;
        const navClass = ' px-4 px-xl-5 py-2 w-100 d-flex justify-content-center position-fixed ';

        return (
            <nav id="navbar" class={this.props.hidden ? 'd-none' : this.props.className ? this.props.className + navClass : navClass}>
                <Container className="px-0 d-flex flex-lg-row align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <Link to={t('url.home')} style={{ marginRight: '1rem' }}>
                            <Logo icon="global"/>
                        </Link>
                        <div class="d-none d-lg-flex flex-row">
                            <ul class="d-flex flex-row align-items-center">
                                {i18n.language === 'fr' && <MenuLink href={t('url.blog')} textLink={t('link.blog')}/> }
                                {i18n.language === 'fr' && <MenuLink href={t('url.newsletter')} textLink={t('link.newsletter')}/> }
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div class="d-none d-lg-flex flex-row align-items-center">
                            {isConnected && <MenuLink href={t('url.editorWF')} textLink={t('link.articles')}/>}
                            {isConnected && <MenuLink customMenuItem={<ProfileAccess onClickLogOut={this.handleLogOut}/>}/> }
                            <MenuLink customMenuItem={
                                <PmyBtn 
                                    redirectTo="#top"
                                    linkIsMediumPmyFull 
                                    iconLinkBefore={<Rocket width="16"/>} 
                                    textLink={t('link.ctaNotConnected')} 
                                    className="rounded-0" 
                                    containerStyle="nav-btn-cta"
                                />
                            }/>
                        </div>
                        <ProfileAccess onClickLogOut={this.handleLogOut} isConnected={isConnected} isPro={isPro} className="d-block d-lg-none"/>
                        <MobileMenu onClickLogOut={this.handleLogOut} isPro={isPro} isConnected={isConnected}/>
                    </div>
                </Container>
            </nav>
        )
    }
}

export default withTranslation()(Navbar);