import React from 'react';
import { withTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import Info from '../../components/elements/Info';
import ConfirmationModal from '../../components/partials/modals/ConfirmationModal';
import Write from '../../../assets/img/svg/switch/FeaturesCta';

class AFTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectArticle: null
        }
        this.createArticle = this.createArticle.bind(this);
    }

    displayModal = (y) => (
        <ConfirmationModal
            trigger={
                <span title={this.props.t('titleElementBrowser.askingFranklin.writeArticle')} class="state-interaction-element d-flex rounded p-2">
                    <Write icon="write" width="14" fill="#2B2B2B"/>
                </span>
            }
            handleClickCtaBtn={() => this.createArticle(y)}
            modalTitle={this.props.t('writingFranklin.sidebar.ctaNewArticle')}
            children={[this.props.t('askingFranklin.data.modalCreateArticle'), '« ', <span class="fw-600">{y}</span>, ' »']}
        />
    );

    createArticle(title) {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/create', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ title: title })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.setState({
                redirectArticle: res.message
            });
        })
    }

    render() {

        const { t } = this.props;

        if (this.state.redirectArticle) {
            return <Navigate to={t('url.editorWF') + '?selected=' + this.state.redirectArticle}/>
        }

        return (
            <>
                <Info msg={t('askingFranklin.data.wheelIndication')} className="info-info mb-3"/>
                <Row className="asking-franklin-table mx-0 px-0 d-flex flex-column flex-md-row">
                    {this.props.data.map((x) => {
                        if (x.suggestions.length > 0) {
                            return (
                                <Col sm="12" md="6" className="mb-3 pb-3 px-3">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th class="align-middle px-2 w-100 fz-18 fw-400">{x.word}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {x.suggestions.map((y) => {
                                                return (
                                                    <tr>
                                                        <td class="align-middle py-1 px-2">{y}</td>
                                                        <td class="px-0 align-middle">{this.displayModal(y)}</td>
                                                    </tr>   
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            );
                        }
                    })}
                </Row>
            </>
        )
    }
}

export default withTranslation()(AFTable);